<template>
  <div>
    <h3>
      {{ $t("users") }} <span class="text-muted">{{ count }}</span>
    </h3>
    <p class="text-muted">{{ $t("usersDescription") }}</p>
    <div class="row justify-content-end mb-2 account-settings">
      <div class="col-8 col-md-6 col-lg-4 col-xl-3">
        <div>
          <span>{{ $t("filterByStatus") }}</span>
        </div>
        <b-form-select
          v-model="isPayment"
          @change="SET_STATUS_FILTER()"
          class="lang-select-box"
        >
          <b-form-select-option
            :value="stat.value"
            v-for="(stat, i) in paymentList"
            :key="i"
            >{{ $t(stat.text) }}</b-form-select-option
          >
        </b-form-select>
      </div>
    </div>
    <Alert :alert="alert" />
    <BaseTable
      i18n
      :loading="isLoading"
      :items="items"
      :count="count"
      :limit="this.limit"
      @change:search="SET_SEARCH"
      :page="page"
      @change:page="SET_PAGINATION"
      :searchPlaceholder="$t('searchCustomerPlaceholder')"
      :pages="pages"
      :headers="headers"
      noCaption
    >
      <template #fullname="{ item }">
        <strong>
          <font-awesome-icon :icon="faUser" />

          {{ item.name }} {{ item.surname }}</strong
        >
        <div>
          <small class="text-muted">
            <font-awesome-icon :icon="faCalendar" />

            {{ setDate(item.createdDate) }}</small
          >
          <small class="text-muted">
            -
            <a class="d-inline-block" :href="`mailto:${item.email}`">
              <font-awesome-icon :icon="faEnvelope" />

              {{ item.email }}</a
            ></small
          >
        </div>
      </template>
      <template #phoneNo="{ item }">
        <a :href="`tel:${item.phoneNo}`">
          <span class="badge badge-soft-dark px-1 w-100">
            <font-awesome-icon :icon="faPhone" />
            {{ item.phoneNo }}</span
          >
        </a>
      </template>
      <template #action="{ item }">
        <div class="mt-3 mt-md-0 d-flex justify-content-end align-items-center">
          <router-link :to="`/admin/kunden/${item.id}`" class="mr-3">
            <button type="button" class="blue-button btn-xs font-size-15">
              {{ $t("viewFiles") }}
              <font-awesome-icon :icon="faEye" />
            </button>
          </router-link>
          <font-awesome-icon
            :icon="faTrash"
            class="text-danger"
            @click="() => deleteUser(item.id)"
          />
        </div>
      </template>
      <template #status="{ item }">
        <div class="text-right text-md-left">
          <div class="position-relative">
            <small
              v-if="item.isPayment"
              :class="`w-100 badge badge-soft-primary`"
              >{{ $t("paymentSucceed") }}</small
            >
            <small v-else :class="`w-100 badge badge-soft-warning`">{{
              $t("thereIsNoPayment")
            }}</small>
          </div>
        </div></template
      >
    </BaseTable>
  </div>
</template>

<script>
import BaseTable from "../../base/Table/index.vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import {
  faEye,
  faPhone,
  faEnvelope,
  faCalendar,
  faUser,
  faCircleUser,
  faUserCheck,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import {
  adminDeleteUser,
  getRegisteredUsers,
  getYears,
} from "../../../services/modules/User";
import { mapGetters } from "vuex";
import {
  assignmentAdminManager,
  filterAdminManager,
  getAdmins,
} from "../../../services/modules/Admin";
import Alert from "../../base/alert/Alert.vue";
import Swal from "sweetalert2";

const year = new Date().getFullYear() - 1;

export default {
  components: {
    BaseTable,
    FontAwesomeIcon,
    Alert,
  },
  data() {
    return {
      faEye,
      faPhone,
      faUser,
      faCalendar,
      faTrash,
      faEnvelope,
      faCircleUser,
      faUserCheck,
      search: "",
      status: -1,
      showAssignmentAdmin: false,
      paymentList: [
        { value: "", text: "allStatus" },
        { value: false, text: "paymentNoSucceedUser" },
        { value: true, text: "paymentSucceedUser" },
      ],
      headers: [
        { title: "fullname", value: "fullname", col: 12, md: 4, lg: 6 },
        { title: "phone", value: "phoneNo", col: 3, md: 3, lg: 2 },
        { title: "status", value: "status", col: 3, md: 2, lg: 2 },
        { title: "", value: "action", col: 12, md: 12, lg: 2 },
      ],
      count: 0,
      page: 1,
      items: [],
      isLoading: true,
      activeUpdateStatus: -1,
      admins: [],
      limit: 10,
      admin: {},
      alert: {
        show: false,
        variant: "",
        message: "",
      },
      filterAdmin: null,
      isPayment: "",
      searchTimeout: -1,
      years: [
        { year: year, hasFile: false },
        { year: year - 1, hasFile: false },
        { year: year - 2, hasFile: false },
        { year: year - 3, hasFile: false },
        { year: year - 4, hasFile: false },
      ],
      taxYear: "",
    };
  },
  computed: {
    ...mapGetters(["activeUser"]),
    pages() {
      return Math.ceil(this.count / 10);
    },
  },
  methods: {
    deleteUser(id) {
      Swal.fire({
        title: `${this.$t("areYouSure")}`,
        text: `${this.$t("questionDeleteCustomer")}`,
        icon: "warning",
        iconColor: "#57c6ff",
        showCancelButton: true,
        confirmButtonColor: "#57c6ff",
        cancelButtonColor: "#f43958",
        confirmButtonText: `${this.$t("confirm")}`,
        cancelButtonText: `${this.$t("giveUp")}`,
      }).then(async (result) => {
        if (result.isConfirmed) {
          const response = await adminDeleteUser(id);

          if (response.message === "OK") {
            Swal.fire({
              title: `${this.$t("success")}`,
              text: `${this.$t("deleteCustomer")}`,
              icon: "success",
              confirmButtonColor: "#57c6ff",
              confirmButtonText: `${this.$t("ok")}`,
            });
            const index = this.items.findIndex((item) => item.id === id);
            this.items.splice(index, 1);
          } else {
            Swal.fire({
              title: `${this.$t("failed")}`,
              text: `${this.$t("noDeleteCustomer")}`,
              icon: "error",
              confirmButtonText: `${this.$t("ok")}`,
            });
          }
        }
      });
    },
    userStatus(val) {
      return val === 0
        ? { class: "info", text: "waiting" }
        : val === 1
        ? { class: "warning", text: "reviewing" }
        : val === 2
        ? { class: "success", text: "completed" }
        : val === 4
        ? { class: "secondary", text: "pendingConfirmation" }
        : val === 5
        ? { class: "primary", text: "transmittedFa" }
        : { class: "danger", text: "canceled" };
    },
    async findAdmin(search, loading) {
      loading(true);
      if (this.searchTimeout !== -1) clearTimeout(this.searchTimeout);
      this.searchTimeout = setTimeout(() => {
        this.adminsList(1, search, "", this.limit);
        loading(false);
      }, 1000);
    },
    async filterAdminList() {
      this.getUsersList();
    },
    async assignmentAdminAction(id, taxYear) {
      const response = await assignmentAdminManager(
        this.admin[id + taxYear].id,
        id,
        taxYear
      );

      if (response.message === "OK") {
        this.alert.show = true;
        this.alert.variant = "success";
        this.alert.message = "successAdminMessage";
        this.getUsersList();
      } else {
        this.alert.show = true;
        this.alert.variant = "danger";
        this.alert.message = "notSuccessAdminMessage";
      }

      setTimeout(() => {
        this.alert.show = false;
      }, 3000);
    },
    async adminsList(page, search, status, limit) {
      const response = await getAdmins(page, search, status, limit);

      if (response.message === "OK") {
        this.admins = response.data.items;
        this.admins.map((item) => {
          return (item.item_data = item.name + " " + item.surname);
        });
      }
    },
    async changeTaxYear() {
      this.page = 1;
      this.$router.push({ query: { ...this.$route.query, page: 1 } });
      this.getUsersList();
    },
    SET_STATUS_FILTER() {
      this.page = 1;
      this.$router.push({
        query: { ...this.$route.query, page: 1, status: this.isPayment },
      });
      this.getUsersList();
    },
    SET_SEARCH(search) {
      this.search = search;
      this.page = 1;
      this.$router.push({ query: { ...this.$route.query, search, page: 1 } });
      this.getUsersList(this.page, this.search, this.status);
    },
    SET_PAGINATION(page) {
      this.page = page;
      this.$router.push({ query: { ...this.$route.query, page } });
      this.getUsersList(this.page, this.search, this.status);
    },
    async getUsersList() {
      this.isLoading = true;
      const response = await getRegisteredUsers(this.page, this.isPayment);
      if (response.message === "OK") {
        this.items = response.data.items;
        this.count = response.data.count;
      } else {
        this.items = [];
        this.count = 0;
      }
      this.isLoading = false;
    },
    setDate(date) {
      return new Date(date + "+00:00").toLocaleString(this.$i18n.locale, {
        day: "2-digit",
        month: "long",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
      });
    },
  },
  mounted() {
    const q = this.$route.query;
    if (!isNaN(q.page)) this.page = Number(q.page);
    if (q.status) this.isPayment = Boolean(q.status) || "";
    if (q.search) this.search = q.search;
    this.getUsersList();
    this.adminsList(1, "", "", this.limit);
  },
};
</script>

<style lang="scss">
.account-settings {
  .lang-select-box {
    border-radius: 20px;
    border: 2px solid #01295f;
    padding: 0 1rem;
  }
}
.status-option-dropdown {
  position: absolute;
  top: 0px;
  width: 100%;
  background: white;
  z-index: 11;
  &__button {
    cursor: pointer;
  }
}

.select-box {
  border-radius: 20px !important;
  font-size: 12px;
}

.o-table__body__item {
  &:hover {
    transition: none !important;
    transform: none !important;
    color: #000;
  }
}
.lang-select-box {
  .vs__dropdown-toggle {
    border: none;
  }
}
.vs__dropdown-toggle {
  border-radius: 20px;
}
.find-admin {
  .vs__dropdown-toggle {
    padding: 4px 10px;
  }
  .vs__clear {
    display: none;
  }
}
</style>
