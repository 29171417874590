<template>
  <div>
    <Users></Users>
  </div>
</template>

<script>
import Users from "@/components/admin/users";
export default {
  components: { Users },
};
</script>

<style>
</style>